const searchPageUrl = '/search';

function getLocalPreselection(pageType) {
  if (pageType === 'category') {
    const breadcrumbs = window.document
      .querySelector('#lblCategoryTrail')
      ?.textContent.split(' > ')
      .filter((t) => t !== 'Home');

    if (breadcrumbs) {
      return [
        {
          field: 'categories',
          term: breadcrumbs.join('>'),
        },
      ];
    }
  }
}

const InitFunc = () => {
  // inject YouTube Modal
  if (window.location.pathname === searchPageUrl) {
    const youtubeModal = `
      <div class="modal fade" id="youtubemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-body" style="text-align: center;">
                      <iframe width="350" height="260" frameborder="0" allowfullscreen=""></iframe>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                  </div>
              </div>
          </div>
      </div>
      `;
    window.document.querySelector('.LayoutContentInner').insertAdjacentHTML('beforeend', youtubeModal);
  }
};

export default {
  InitFunc,
  searchPageUrl,
  defaultView: 'col3',
  getLocalPreselection,
  product: {
    fields: {
      id: 'id',
      image: 'primary_picture',
      title: 'item_name',
      url: 'url_rewrite',
    },
  },
  SearchRequestDefaults: {
    pageSize: 24,
    sort: 'created_at:desc',
  },
  page: {
    getPageType: (defaults) =>
      window.document.querySelector('body.Category') ? 'category' : defaults.getter(),
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_searchPage',
      template: 'MainContent',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: { replace: '.product-list' },
      template: 'MainContent',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'created_at:desc': 'Newest Products',
        'relevance': 'Relevancy',
        'Price': 'Priced Low to High',
        'Price:desc': 'Priced High to Low',
      },
      pageSizes: [12, 24, 36, 48, 60],
    },
    {
      name: 'SearchResult',
      updateCallback: () => handleProductCards(),
    },
    {
      name: 'FacetPanel',
      location: '#cm_facets',
      template: 'FacetPanelContainer',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
    },
    {
      name: 'CategoryPageFacetPanel',
      type: 'FacetPanel',
      location: '#cm_facets_cat',
      template: 'FacetPanelContainer',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '#cm_searchBox',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};

function handleProductCards() {
  $('.ytLink').on('click', function (y) {
    y.preventDefault();
    $('#youtubemodal iframe').attr('src', $(this).attr('href'));
  });

  $('#youtubemodal').on('hidden.bs.modal', function () {
    $('#youtubemodal iframe').attr('src', $('#youtubemodal iframe').attr('src'));
  });
}
