//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-264:_7000,_9048,_2948,_3436,_8484,_3712,_1616,_9928;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-264')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-264', "_7000,_9048,_2948,_3436,_8484,_3712,_1616,_9928");
        }
      }catch (ex) {
        console.error(ex);
      }