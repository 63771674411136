export default {
  BUTTON_PRIMARY_CLASS: 'button btn btn-primary',
  BUTTON_SECONDARY_CLASS: 'button btn btn-primary',
  LOAD_MORE_BUTTON_CLASS: 'btn btn-default',
  FACET_BUTTON_CLASS: '',

  PRODUCT_REPEATER_CLASS: `items-container{this.view === 'col3' ? ' row col3 altViewRow' : ' row col2 cm_loading-relative'}`,
  SORT_CONTAINER_CLASS: 'catSortDD in-block v-mid pad-r-20',
  LOAD_MORE_TEXT: 'Load more',
  FILTERS_TEXT: 'FILTER RESULTS',

  SEARCH_BOX_FORM_CLASS: 'search-form',
  SEARCH_BOX_INPUT_CLASS: 'search-text form-control',
  SEARCH_BOX_CLEAR_BUTTON: 'Clear',
  SEARCH_BOX_PLACEHOLDER: '',

  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',
  SHOW_MORE: 'More',
  SHOW_LESS: 'Less',
  CHIPS_DISCARD: '<span class="discard">&times;</span>',

  AUTOCOMPLETE_PRODUCT_PRICE_CLASS: 'catAltPrice',
  AUTOCOMPLETE_PRODUCT_TITLE_CLASS: 'catProdNamef',
};
